import { ChartColor } from "../../../enums/colors";
import { BrandCampaignInsightEngagementChart } from "../../../models/brandCampaignInsight";

export interface EngagementChartEntry {
  prop: keyof BrandCampaignInsightEngagementChart;
  color: `#${string}`;
}

export const engagementChartData: EngagementChartEntry[] = [
  {
    prop: "calendarFullyAccepted",
    color: ChartColor.Green1000,
  },
  {
    prop: "manuallyPlanned",
    color: ChartColor.Green700,
  },
  {
    prop: "downloadedContent",
    color: ChartColor.Green400,
  },
  {
    prop: "publishedGoogleAdsAutomatically",
    color: ChartColor.Green200,
  },
];
