import type { ChartConfiguration } from "chart.js";

export const doughnutChartSettings = (): ChartConfiguration<"doughnut"> => ({
  data: {
    datasets: [],
  },
  plugins: [],
  type: "doughnut",
  options: {
    cutout: 35,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
    },
  },
});
