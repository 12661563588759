import { ChartColor, GrayColor } from "../../../enums/colors";
import { BrandCampaignInsightPartnerStatusChart } from "../../../models/brandCampaignInsight";

export interface PartnerStatusChartEntry {
  prop: keyof BrandCampaignInsightPartnerStatusChart;
  color: `#${string}`;
}

export const partnerStatusChartData: PartnerStatusChartEntry[] = [
  {
    prop: "accepted",
    color: ChartColor.Blue,
  },
  {
    prop: "invited",
    color: GrayColor.C500,
  },
  {
    prop: "validated",
    color: ChartColor.Teal,
  },
  {
    prop: "pendingValidation",
    color: ChartColor.Amber,
  },
  {
    prop: "engaged",
    color: ChartColor.Green1000,
  },
  {
    prop: "cancelled",
    color: ChartColor.Red,
  },
];
