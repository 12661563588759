import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { lastValueFrom } from "rxjs";
import { map } from "rxjs/operators";
import {
  BrandCampaignInsight,
  BrandCampaignInsightChart,
  BrandInsightPartner,
} from "../../../../shared/models/brandCampaignInsight";
import { BrandService } from "../../../../shared/services/api/brand.service";
import { HttpClient } from "../../../../shared/services/http-client";

// Provided in root so that it can be injected in dialogs
@Injectable({ providedIn: "root" })
export class BrandCampaignInsightService {
  private brandLegacyInsights: Record<number, BrandCampaignInsight> = {};
  private brandInsights: Record<number, BrandCampaignInsight> = {};
  private brandInsightsLegacyCharts: Record<number, BrandCampaignInsightChart> =
    {};
  private brandInsightsCharts: Record<number, BrandCampaignInsightChart> = {};
  private brandPartnerInsights: Record<number, BrandInsightPartner[]> = {};
  private path = "brand";

  constructor(
    private readonly http: HttpClient,
    private readonly brandService: BrandService,
  ) {}

  public async getBrandCampaignInsights(
    campaignId: number,
    cached = true,
  ): Promise<BrandCampaignInsight> {
    const brandId = this.brandService.currentBrandId;

    if (this.brandInsights && this.brandInsights[campaignId] && cached) {
      return this.brandInsights[campaignId];
    }

    return lastValueFrom(
      this.http
        .get(`${this.path}/${brandId}/campaign/${campaignId}/insights`)
        .pipe(
          map(({ body }) => {
            const brandInsight = new BrandCampaignInsight(body);
            this.brandInsights[campaignId] = brandInsight;
            return brandInsight;
          }),
        ),
    );
  }

  public async getBrandCampaignLegacyInsights(
    campaignId: number,
    cached = true,
  ): Promise<BrandCampaignInsight> {
    const brandId = this.brandService.currentBrandId;

    if (
      this.brandLegacyInsights &&
      this.brandLegacyInsights[campaignId] &&
      cached
    ) {
      return this.brandLegacyInsights[campaignId];
    }

    return lastValueFrom(
      this.http
        .get(`${this.path}/${brandId}/campaign/${campaignId}/insights`)
        .pipe(
          map(({ body }) => {
            const brandInsight = new BrandCampaignInsight(body);
            this.brandLegacyInsights[campaignId] = brandInsight;
            return brandInsight;
          }),
        ),
    );
  }

  public async getBrandCampaignInsightsCharts(
    campaignId: number,
    cached = true,
  ): Promise<BrandCampaignInsightChart> {
    const brandId = this.brandService.currentBrandId;
    if (
      this.brandInsightsCharts &&
      this.brandInsightsCharts[campaignId] &&
      cached
    ) {
      return this.brandInsightsCharts[campaignId];
    }
    return lastValueFrom(
      this.http
        .get(`${this.path}/${brandId}/campaign/${campaignId}/insights_charts`)
        .pipe(
          map(({ body }) => {
            const brandInsight = new BrandCampaignInsightChart(body);
            this.brandInsightsCharts[campaignId] = brandInsight;
            return brandInsight;
          }),
        ),
    );
  }

  public async getBrandCampaignInsightsLegacyCharts(
    campaignId: number,
    cached = true,
  ): Promise<BrandCampaignInsightChart> {
    const brandId = this.brandService.currentBrandId;
    if (
      this.brandInsightsLegacyCharts &&
      this.brandInsightsLegacyCharts[campaignId] &&
      cached
    ) {
      return this.brandInsightsLegacyCharts[campaignId];
    }
    return lastValueFrom(
      this.http
        .get(`${this.path}/${brandId}/campaign/${campaignId}/insights_charts`)
        .pipe(
          map(({ body }) => {
            const brandInsight = new BrandCampaignInsightChart(body);
            this.brandInsightsLegacyCharts[campaignId] = brandInsight;
            return brandInsight;
          }),
        ),
    );
  }

  public async getBrandCampaignPartnerInsights(
    campaignId: number,
    cached = true,
  ): Promise<BrandInsightPartner[]> {
    const brandId = this.brandService.currentBrandId;

    if (
      this.brandPartnerInsights &&
      this.brandPartnerInsights[campaignId] &&
      cached
    ) {
      return this.brandPartnerInsights[campaignId];
    }

    return lastValueFrom(
      this.http
        .get(`${this.path}/${brandId}/campaign/${campaignId}/partner_insights`)
        .pipe(
          map(({ body }) => {
            const partnerInsights: BrandInsightPartner[] = [];

            body.map((partnerInsightData: any) => {
              partnerInsights.push(new BrandInsightPartner(partnerInsightData));
            });

            this.brandPartnerInsights[campaignId] = partnerInsights;
            return partnerInsights;
          }),
        ),
    );
  }

  public getBrandCampaignInsightsFromToken(
    token: string,
  ): Observable<BrandCampaignInsight> {
    return this.http
      .get(`public/campaign-insights-screen-shot/${token}`)
      .pipe(map(({ body }) => new BrandCampaignInsight(body)));
  }

  public getBrandCampaignInsightChartsFromToken(
    token: string,
  ): Observable<BrandCampaignInsightChart> {
    return this.http
      .get(`public/campaign-insights-charts-screen-shot/${token}`)
      .pipe(map(({ body }) => new BrandCampaignInsightChart(body)));
  }
}
